<template>
  <el-form inline :model="selectModel" label-position="right">
    <el-form-item label="用户ID">
      <el-input v-model="selectModel.userId" />
    </el-form-item>
    <el-form-item label="类型">
      <el-select v-model="selectModel.type" placeholder="请选择" clearable>
        <el-option v-for="(a,b) in $store.state.feedbackType" :key="b" :value="b" :label="a" />
      </el-select>
    </el-form-item>
    <el-form-item label="状态">
      <el-select v-model="selectModel.status" placeholder="请选择" clearable>
        <el-option v-for="(a,b) in $store.state.feedbackStatus" :key="b" :value="b" :label="a" />
      </el-select>
    </el-form-item>
    <el-form-item label="创建时间">
      <el-date-picker
        v-model="selectDatetime"
        type="datetimerange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期" />
    </el-form-item>
    <el-form-item class="main-form-item-float-right">
      <el-button-group>
        <el-button type="primary" icon="el-icon-search" @click="queryTableData">查询</el-button>
      </el-button-group>
    </el-form-item>
  </el-form>

  <el-table
    :data="tableData"
    border
    stripe
    :default-sort="defaultSort"
    @sort-change="sort"
    style="width: 100%;">
    <el-table-column label="ID" prop="id" width="60px" align="center" sortable="custom" />
    <el-table-column label="创建时间" prop="createTime" min-width="180px" />
    <el-table-column label="用户ID" prop="userId" min-width="100px" sortable="custom" />
    <el-table-column label="类型" prop="type" min-width="150px">
      <template #default="scope">
        {{ $store.getters.columnIdToVal('feedbackType', scope) }}
      </template>
    </el-table-column>
    <el-table-column label="关联用户订单" prop="userOrderId" min-width="120px" />
    <el-table-column label="联系方式" prop="phone" min-width="120px" />
    <el-table-column label="内容" prop="content" min-width="200px" />
    <el-table-column label="状态" prop="status" min-width="80px">
      <template #default="scope">
        {{ $store.getters.columnIdToVal('feedbackStatus', scope) }}
      </template>
    </el-table-column>
    <el-table-column
      fixed="right"
      label="操作"
      align="center"
      width="80">
      <template #default="scope">
        <el-button v-if="scope.row.status === 1" @click="resolveAciton(scope.row)" type="text" size="small">解决</el-button>
        <span v-else>-</span>
      </template>
    </el-table-column>
  </el-table>
  <div style="margin-top: 10px; text-align: right;">
    <el-pagination
      layout="prev, pager, next,jumper"
      :total="total"
      @current-change="applyCurrentPage" />
  </div>
</template>

<script>
import { handleCatchStatus } from '../../api/handler';
import { format } from '../../utils/datetime';

export default {
  data() {
    return {
      selectDatetime: [],
      selectModel: {},
      selectSort: null,
      defaultSort: {prop: 'id', order: 'descending'},
      tableData: [],
      total: 0,
      current: 1,
      dialog: {
        visibled: false,
        created: false,
        readonly: false,
        title: '',
        form: {},
        submit: () => true
      },
      roles: []
    };
  },
  created() {
    this.selectModel.collegeId = this.$route.query.collegeId;
    this.applySortSelect(this.defaultSort);
    this.queryTableData();
    console.log(this.selectModel);
  },
  mounted() {
  },
  methods: {
    queryTableData() {
      this.applyDatetime();
      this.selectModel.sort = this.selectSort;
      this.selectModel.page = this.current - 1;
      this.$api.getUserFeedbackPage(this.selectModel)
        .then(data => {
          this.tableData = data.content;
          this.total = data.totalElements;
        })
        .catch(handleCatchStatus);
    },
    applyDatetime() {
      if (this.selectDatetime && this.selectDatetime.length === 2) {
        this.selectModel.startTime = format(this.selectDatetime[0]);
        this.selectModel.endTime = format(this.selectDatetime[1]);
      } else {
        this.selectModel.startTime = null;
        this.selectModel.endTime = null;
      }
    },
    sort({ prop, order }) {
      if (prop === 'collegeName') {
        prop = 'collegeId';
      }
      this.applySortSelect({ prop, order });
      this.queryTableData();
    },
    applySortSelect({ prop, order }) {
      if (order) {
        this.selectSort = `${prop},${order === 'ascending' ? 'ASC' : 'DESC'}`;
        return;
      }
      this.selectSort = null;
    },
    applyCurrentPage(current) {
      this.current = current;
      this.queryTableData();
    },
    resolveAciton(data) {
      this.$confirm('此操作将标记该条反馈为已处理状态, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => this.$api.updateUserFeedbackStatus(data.id, 2))
        .then(() => {
          this.$message.success('处理成功!');
          this.queryTableData();
        })
        .catch(() => this.$message.info('已取消处理'));
    }
  }
};
</script>
